//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  Layout
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
table{
  &.table-sortable{
    tbody > tr {
      cursor:move;
    }
  }
  &.table-gray{
    tbody > tr:hover{
      td{
        background-color:#f4f4f4;
      }
    }
  }
  tbody > tr:hover{
    td{
      background-color:#f4f4f4;
    }
  }
}
.chosen-results {
  //height: 150px;
}
.panel{
  .panel-heading{
    display:none;
  }
}

.visible-600{
  display:none !important;
}
span.preloader{
  background: url('/assets/img/console/ajaxloading.gif') center center no-repeat;
  width: 25px;
  height: 22px;
  margin: 0 7px;
  float: left;
  display: none;
}
.popover-content, .popover-content p{
  word-wrap:break-word;
  word-break:break-all;
}
td{
  word-break:break-all;
}
.row-fluid{
  overflow:visible;
  &:after{
    clear: both;
  }
}
.c-inner {
  position:relative;
  margin:0 auto;
  min-height:10px;
  width: 96%;
  max-width: 1250px;
  min-width: 1250px;
  h2:first-child {
    padding: 15px 0px;
  }
  .page-header {
    margin: 0px;
    input, a.btn {
      margin-top: 14px;
    }
  }
}

.c-header {
  height:80px;
  background-color:#2f2f2f;
  padding:0px 12px;
  border-radius:0px;
  #logo {
    padding:0px 0px 5px;
    height:75px;
    position:absolute;
    top:0px;
    left:50%;
    margin:0px 0px 0px -150px;
    width:300px;
    text-align:center;
    img {
      height:75px;
      margin:0 auto;
    }
  }
  .btn-menu{
    color: #aaa;
    font-size: 16px;
    text-decoration: none;
    padding: 0px 12px;
    margin: 18px 0px 18px 12px;
    display: block;
    border: 0;
    line-height: 44px;
    float:right;
    border-radius: 5px;
    background-color: darken(#2f2f2f, 5%);
    &:hover {
      color: lighten(#aaa, 10%);
      background-color: darken(#2f2f2f, 10%);
    }
  }
  #menu {
    padding:0px;
    margin:0px;
    display:block;
    float:right;
    li {
      display:inline;
      list-style-type:none;
      float:right;
      &:first-of-type{
        float:left;
      }
      a, button {
        color: #aaa;
        font-size: 16px;
        text-decoration: none;
        padding: 0px 12px;
        margin: 18px 0px 18px 12px;
        display: block;
        border: 0;
        line-height: 44px;
        border-radius: 5px;
        background-color: darken(#2f2f2f, 5%);
      }
      &:hover a, &:hover button {
        color: lighten(#aaa, 10%);
        background-color: darken(#2f2f2f, 10%);
      }
    }
  }
}

///*********************************
//  MENU
//*********************************/

.c-menu {
  min-height:238px;
  background-color: #444;
  .login & {
    min-height: 80px;
  }
  .c-main-tabs {
    padding:0px 6px;
    .c-main-tab {
      float:left;
      min-width: 120px;
      height:110px;
      a {
        display:block;
        height:90px;
        margin:10px 0px 10px 10px;
        color:#fff;
        border-radius:5px;
        padding:0px 10px 0px 10px;
        &:hover {
          background:#428bca;
          text-decoration:none;
          color:#fff;
        }
      }
    }
  }
}

.c-menu .c-main-tabs .c-main-tab.active a {
  background: #428bca;
  text-decoration: none;
  color: #fff;
  height: 100px;
  border-radius: 5px 5px 0px 0px;
}

.c-menu .c-main-tabs .c-main-tab i {
  font-size: 38px;
  line-height: 50px;
  height: 60px;
  padding-top: 12px;
  display: block;
  text-align: center;
}

.c-menu .c-main-tabs .c-main-tab span {
  font-size: 14px;
  line-height: 16px;
  display: block;
  text-align: center;
}

.c-menu .c-sub-menu {
  background:#428bca;
  height:50px;
  width:100%;
  border-radius: 5px 5px 0px 0px;
  ul {
    margin:0px;padding:0px 8px;height:48px;list-style-type:none;
    li {
      float:left;
      a {
        display: inline-block;
        margin: 8px 8px 8px 0px;
        border-radius:  5px;
        padding: 2px 10px;
        line-height: 28px;
        color: #fff;
        font-size: 16px;
      }
    }
  }
  ul li a:hover, ul li.active a {
    background-color: darken(#428bca, 15%);
    text-decoration: none;
  }
}


///*********************************
//  CONTENT
//*********************************/
.tile_delete, .tile_edit{
  display:block;
  width:100%;
  margin:0.4em 0 0 0;
}
.dataTables_wrapper{
  .row-fluid{
    .exception{
      padding:0;
    }
  }
  .dataTable{
    clear:both;
    tbody{
      tr{
        td{
          &.actions{
            .icon{
              display:inline-block;
              font-weight:900;
              font-size:1em;
            }
          }
        }
        &.child{
          position:relative;
          padding-left:30px;
          .child{
            position:relative;
            padding-left:30px;
          }
          .table-spacer{
            width:20px;
            background:#ccc;
            height:47px;
            position:absolute;
            top:0px;
            left:0px;
          }
        }
        &.subchild{
          position:relative;
          padding-left:50px;
          .subchild{
            position:relative;
            padding-left:50px;
          }
          .table-spacer{
            width:40px;
            background:#ccc;
            height:47px;
            position:absolute;
            top:0px;
            left:0px;
          }
        }
      }
    }
  }
}
.dataTables_length{
  label select{
    height:38px;
  }
}
.row{
  &.form-row{
    margin:5px 0;
    float: left;
    width:100%;
    .col{
      padding:0;
      &.form-label{
        text-align:left;
        min-height:30px;
        line-height: 30px;
        min-height: 0;
        span{
          &.mandatory{
            color:red;
            float:left;
          }
        }
      }
    }
  }
 }
.c-canvas {
  min-height: 500px;
  padding: 4px 12px 12px;
  border-radius: 0px 0px 5px 5px;
  background: #fff;
  h1, h2, h3 {
    margin: 0px;
  }
  body.login & {
    border-radius: 5px;
    margin: 30px auto 10px;
    min-height: 20px;
    width: 400px;
    position: relative;
    padding: 12px;
    &.missing{
      width:100%;
      max-width:1250px;
      min-height:200px;
    }
    h1, h2, h3 {
      margin: 12px 0px;
      text-align: center;
    }
    .input-group{
      &.first{
        margin-bottom: 1em;
      }
    }
  }
}

.c-canvas .header {
  padding-bottom:8px;
  border-bottom:1px solid #ccc;
  border-right: 0;
  margin-bottom:10px;
  margin-top:10px;
  overflow:hidden;
  .btn.pull-right {
    margin-left: 5px;
  }
  form{
    width:auto;
    margin:0.5em 0 0 0;
    select{
      width:auto;
    }
  }
}

/*** CATEGORY MAIN PAGE BOXES ***/
.c-sections { margin:30px auto; }
.c-sections .c-section { max-width:200px;margin:0 auto;min-height:120px;padding:40px 6px;
      -webkit-transition: background-color 0.5s;
      -moz-transition: background-color 0.5s;
      -o-transition: background-color 0.5s;
      -ms-transition: background-color 0.5s;
      transition: background-color 0.5s;
    }
.c-sections .c-section:hover { background:#555; }
.c-sections .c-section a { text-align:center;text-decoration:none;color:#f28a20;text-shadow:-1px 1px 1px #ccc; }
.c-sections .c-section i { margin:0 auto;font-size:101px;color:#f28a20;padding:20px 46px;width:108px;text-align:center; }
.c-sections .c-section h4 { font-size:24px; }

///*********************************
//  Tile
//*********************************/
.well-extra{
  padding:0;
  border-radius: 3px;
}
.tile_selector{
  border:0;
  cursor:pointer;
  &.active, &:hover{
    opacity:0.3;
  }
  &.special{
    position:relative;
    span{
      position:absolute;
      top:50%;
      left:0;
      margin:-10px 0 0 0px;
      text-align:center;
      width:100%;
      right:0;
      font-weight:bold;
    }
  }
}
.instruction_special{
  border:0;
  cursor:pointer;
  position:relative;
  span{
    position:absolute;
    top:50%;
    left:0;
    margin:-10px 0 0 0px;
    text-align:center;
    width:100%;
    right:0;
    font-weight:bold;
  }
}
.tile{
  a{
    padding:30px 20px;
    display:block;
    text-align:center;
    &:hover{
      text-decoration:none;
    }
    .icon{
      margin-top:10px;
      display:block;
      font-size:4em;
    }
  }
}
.tile_gallery{
  margin:1em 1em 0 0;
  position:relative;
  .actions{
    opacity:0;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    padding:0.5em;
    color:white;
    transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
  }
  &:hover{
    .actions{
      opacity:1;
    }
  }
}
.tile_gallery_temp{
  width:19%;
  opacity:0.5;
  margin:0 1% 0 0;
  background-color:#eee;
  float:left;
  position:relative;
  min-height:260px;
}
.tile_img{
  margin-bottom: 20px;
  .preloader{
    float:none;
    &.active{
      display:inline-block;
    }
  }
  .well{
    padding: 1em;
    margin-bottom: 0px;
    height: 305px;
    position: relative;
    &.link_edit{
      position:absolute;
      top:0;
      left:15px;
      right:15px;
      z-index: 10;
    }
  }
  .tile_image_content{
    display:block;
    text-align:center;
    position:absolute;
    bottom:12px;
    left:0;
    right:0;
  }
  p{
    display:block;
    padding:0 0.5em;
  }
  img{
    max-width: 100%;
    margin: 0 auto 3.5em auto;
    max-height: 200px;
    display:block;
    text-align:center;
  }
  button{
    display:inline;
    margin:0 0.5em;
  }
}
.video_title{
  min-width:120px;
}

///*********************************
//  FOOTER
//*********************************/

.c-footer {
  color:#333;font-size:12px;line-height:30px;padding:0px 12px;
}
body.login .c-footer p { float:none;width:400px;text-align:center;display:block;margin:0 auto; }

///*********************************
//  LOGIN
//*********************************/

.c-canvas h2.c-login-header { text-align:center;position:absolute;top:0px;left:0px;width:400px;background:#ededed;padding:6px 12px;border-bottom:1px solid #d1d1d1; }
.c-canvas .c-login-actions { position:absolute;bottom:0px;left:0px;width:400px;background:#ededed;padding:6px 12px;border-top:1px solid #d1d1d1;height:40px; }
.c-canvas .c-login-actions a.btn { margin:3px 0px; }
.c-canvas .c-login-actions .c-login-checkbox { padding:8px 0px; }
.c-canvas .c-login-fields { height:150px; }
.c-canvas .c-login-field { margin-top:10px; }

///*********************************
//  ADATATABLE
//*********************************/

.dataTables_wrapper .span6 { padding:0px; }
.dataTables_wrapper .span6 input { width:220px; }
.dataTables_wrapper .span6 .dataTables_length label select { width:100px; }
.dataTables_paginate.paging_bootstrap.pagination { margin:0px; float:right; }
.dataTables_filter { float:right; }
.dataTables_processing{
  margin: 1em 0;
  float: left;
}

.pagination {
  height: 40px;
  margin: 20px 0;
}

.pagination ul {
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  *zoom: 1;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination li {
  display: inline;
}

.pagination a,
.pagination span {
  float: left;
  padding: 0 14px;
  line-height: 38px;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-left-width: 0;
}

.pagination a:hover,
.pagination .active a,
.pagination .active span {
  background-color: #f5f5f5;
}

.pagination .active a,
.pagination .active span {
  color: #999999;
  cursor: default;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  cursor: default;
  background-color: transparent;
}

.pagination li:first-child a,
.pagination li:first-child span {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
     -moz-border-radius: 3px 0 0 3px;
          border-radius: 3px 0 0 3px;
}

.pagination li:last-child a,
.pagination li:last-child span {
  -webkit-border-radius: 0 3px 3px 0;
     -moz-border-radius: 0 3px 3px 0;
          border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pager {
  margin: 20px 0;
  text-align: center;
  list-style: none;
  *zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  line-height: 0;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
}

.pager a {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
     -moz-border-radius: 15px;
          border-radius: 15px;
}

.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next a {
  float: right;
}

.pager .previous a {
  float: left;
}

.pager .disabled a,
.pager .disabled a:hover {
  color: #999999;
  cursor: default;
  background-color: #fff;
}

.row-fluid .metrouitab {padding:0px;}
.row-fluid .metrouitab.first a {margin-left:0px;}
.row-fluid .metrouitab.last a {margin-right:0px;}
.row-fluid .metrouitab:hover a {border:3px #333 solid;padding:0;}
.row-fluid .metrouitab a{display:block;min-height:50px;color:#fff;font-size:20px;
    text-align:center;line-height:50px;margin:5px;padding:3px;}
.row-fluid .metrouitab a:hover{text-decoration:none;}
.row-fluid .metrouitab.blue a{background:#428bca;}
.row-fluid .metrouitab.lightblue a{background:#5bbbe4;}
.row-fluid .metrouitab.darkblue a{background:#156486;}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  Loading Gif
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.loading{
  height:20px;
  width:20px;
  display:block;
  background: transparent url('/assets/img/console/ajaxloading.gif') no-repeat top left;
}
.file-delete, .delete-item{
  position:relative;
}
button{
  &.file-delete{
    .loading{
      position: absolute;
      left: 115%;
      top: 8px;
    }
  }
}
.text-right{
  .loading{
    display:inline-block;
    position:relative;
    top:6px;
    margin-right: 0.5em

  }
}

input[type=file]{
  height: 35px;
  padding-bottom: 3em;
}

.panel-primary{
  border-color: #ddd;
}

.teehee{
  cursor:pointer;
  position: fixed;
  bottom: 0;
  right: -150px;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  &.active{
    right:10px;
  }
  i{
    font-size:10em;
    color:black;
  }
}

.input-group{
  width:100%;
}


.short{
  label{
    display: inline;
  }
  .radio{
    display: inline-block;
    margin-top: 8px;
    margin-left: 20px;
  }
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  Libs Oveerides
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 * Reset table top border
 */
 .table caption+thead tr:first-child th, .table colgroup+thead tr:first-child th, .table thead:first-child tr:first-child th, .table caption+thead tr:first-child td, .table colgroup+thead tr:first-child td, .table thead:first-child tr:first-child td {
  border-top: 1px solid #ccc;
 }

/**
 * Form styles
 */
select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
  &.parsley-error {
    background: #fdf2f2;
    border-color: #ee6060;
  }
}

.parsley-error-list {
  display: none!important;
  height: 0px;
  li {
    list-style-type: none;
  }
}
input:focus {
  background: #efefef;
}
input.parsley-error {
  border: 1px solid #D6322C;
  box-shadow: 0px 0px 6px rgba(#D6322C, 0.3);
}
select {
  margin-left: 5px;
}

/**
 * Button colors + borders
 */
.btn {
  padding: 9px 12px 6px;
  border: none;
  border-bottom: 3px solid #474949;
  &.btn-small {
    padding: 6px 10px 3px;
  }
}

.btn-default {
  color: #ffffff;
  background-color: #474949;
  border-color: darken(#474949, 20%);
  &:hover, &:focus, &:active, &.active {
    background-color: darken(#474949, 8%);
    border-color: darken(#474949, 20%);
  }
}

.btn-primary {
  color: #ffffff;
  background-color: #428bca;
  border-color: darken(#428bca, 20%);
  &:hover, &:focus, &:active, &.active {
    background-color: darken(#428bca, 8%);
    border-color: darken(#428bca, 20%);
  }
}

.btn-warning {
  color: #ffffff;
  background-color: #f0ad4e;
  border-color: darken(#f0ad4e, 20%);
  &:hover, &:focus, &:active, &.active {
    background-color: darken(#f0ad4e, 8%);
    border-color: darken(#f0ad4e, 20%);
  }
}

.btn-danger {
  color: #ffffff;
  background-color: #d9534f;
  border-color: darken(#d9534f, 20%);
  &:hover, &:focus, &:active, &.active {
    background-color: darken(#d9534f, 8%);
    border-color: darken(#d9534f, 20%);
  }
}

.btn-success {
  color: #ffffff;
  background-color: #5cb85c;
  border-color: darken(#5cb85c, 20%);
  &:hover, &:focus, &:active, &.active {
    background-color: darken(#5cb85c, 8%);
    border-color: darken(#5cb85c, 20%);
  }
}

.btn-info {
  color: #ffffff;
  background-color: #5bc0de;
  border-color: darken(#5bc0de, 20%);
  &:hover, &:focus, &:active, &.active {
    background-color: darken(#5bc0de, 8%);
    border-color: darken(#5bc0de, 20%);
  }
}

/**
 * Alert boxes
 */
.alert {
  border: none;
  border-top-width: 3px;
  border-top-style: solid;
}

/*
  Nice map picker
*/
.address-picker {
  .well#no-address-message {
    width: 100%;
    height: 175px;
    p {
      padding: 25px 0;
    }
  }
  .well#no-address-message.loading {
    background: url(/assets/img/console/spinner.gif) center center no-repeat whitesmoke;
    p {
      display: none;
    }
  }
  #map {
    height: 175px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2px;
  }
}
